/*
 * @作者: LiChaoquan
 * @Date: 2021-03-25 16:03:18
 */
import { Loading } from "element-ui";
import { Message } from "element-ui";
const axios = require("axios");
// 创建axios实例
export const instance = axios.create({
  timeout: 100000,
});

let evnUrl = "https://trpt.jyzh56.com/transport_api/";
//let evnUrl = "https://56gangkou.com/jy-transport";
//let evnUrl =  = "http://192.168.11.10:9090";
//let evnUrl = "http://192.168.31.43:9090";
export var http = function (baseUrl, api, method, props) {
  if (!baseUrl || !api || !method) throw new Error("参数错误");
  switch (baseUrl) {
    case "api1":
      instance.defaults.baseURL = evnUrl;
      break;
  }
  instance.defaults.responseType = "json";
  switch (api) {
    case "/captcha.jpg":
      instance.defaults.responseType = "arraybuffer";
      break;
  }
  if (method === "get") {
    if (props) return instance.get(api, { params: props });
    return instance.get(api);
  } else if (method === "post") {
    return instance.post(api, props);
  }
};
export var getBlob = function (method, url, props) {
  let loadingInstance = Loading.service({
    fullscreen: true,
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
  axios({
    method: method,
    url: evnUrl + url,
    data: props,
    params: props,
    responseType: "blob",
    headers: {
      token: JSON.parse(
        decodeURIComponent(window.sessionStorage.getItem("user"))
      ).token,
    },
  }).then((res) => {
    if (res.headers["content-type"] == "application/json") {
      loadingInstance.close();
      let reader = new FileReader();
      let blob = new Blob([res.data], {
        type: res.headers["content-type"],
      });
      reader.readAsText(blob);
      reader.onloadend = (text) => {
        Message.error(JSON.parse(text.target.result).msg);
      };
      return;
    }
    let blob = new Blob([res.data], {
      type: res.headers["content-type"],
    });
    loadingInstance.close();
    let fileName = "";
    if (res.headers["content-disposition"]) {
      fileName = decodeURIComponent(
        res.headers["content-disposition"].split(`filename*=utf-8''`)[1]
      );
    } else {
      fileName = new Date().getTime() + ".xlsx";
    }
    const link = document.createElement("a");
    link.download = fileName;
    link.href = window.URL.createObjectURL(blob);
    link.click();
    window.URL.revokeObjectURL(link.href);
  });
};
