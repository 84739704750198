const {
  Menu,
  Progress,
  Submenu,
  MenuItem,
  Row,
  Col,
  Table,
  TableColumn,
  Form,
  FormItem,
  Pagination,
  Message,
  MessageBox,
  Select,
  Option,
  Radio,
  RadioGroup,
  RadioButton,
  Tooltip,
  DatePicker,
  Loading,
  Button,
  Upload,
  CheckboxGroup,
  Checkbox,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Image,
  Input,
  Dialog,
  Notification,
  Tag,
  Descriptions,
  DescriptionsItem,
  Empty,
  Container,
  Main,
  Aside,
  Header,
  Cascader,
  Tree,
  Switch,
  Tabs,
  TabPane,
  Badge,
} = require("element-ui");
// collapse 展开折叠
module.exports = {
  install(Vue) {
    Vue.use(Descriptions);
    Vue.use(Empty);
    Vue.use(DescriptionsItem);
    Vue.use(Menu);
    Vue.use(Progress);
    Vue.use(Submenu);
    Vue.use(MenuItem);
    Vue.use(Row);
    Vue.use(Col);
    Vue.use(Table);
    Vue.use(TableColumn);
    Vue.use(Form);
    Vue.use(FormItem);
    Vue.use(Pagination);
    Vue.use(Select);
    Vue.use(Option);
    Vue.use(Radio);
    Vue.use(RadioGroup);
    Vue.use(RadioButton);
    Vue.use(Tooltip);
    Vue.use(DatePicker);
    Vue.use(Button);
    Vue.use(Upload);
    Vue.use(CheckboxGroup);
    Vue.use(Checkbox);
    Vue.use(Dropdown);
    Vue.use(DropdownMenu);
    Vue.use(DropdownItem);
    Vue.use(Image);
    Vue.use(Input);
    Vue.use(RadioGroup);
    Vue.use(Tag);
    Vue.use(Dialog);
    Vue.use(Container);
    Vue.use(Aside);
    Vue.use(Header);
    Vue.use(Main);
    Vue.use(Cascader);
    Vue.use(Tree);
    Vue.use(Switch);
    Vue.use(Tabs);
    Vue.use(TabPane);
    Vue.use(Badge);

    Vue.prototype.$message = Message;
    Vue.prototype.$loading = Loading;
    Vue.prototype.$messageBox = MessageBox;
    Vue.prototype.$notification = Notification;

  },
};
