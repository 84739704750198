import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: "",
    navigationMsg: null,
    isCollapse: false,
    conditionsList: {}, //查询条件的数组
    titleListNew: JSON.parse(
      window.sessionStorage.getItem("titleListNew") ||
        `[{"id":101,"name":"首页","path":"/home/homepage","icon":"icon-shouye"}]`
    ),
    pendingCount: [
      {
        index: "/check/cargoSourceCheck",
        key: "plan",
        count: 0,
      },
      {
        index: "/check/fleetCheck",
        key: "fleet",
        count: 0,
      },
      {
        index: "/check/cargoOwnerCheck",
        key: "enterprise",
        count: 0,
      },
      {
        index: "/check/driverVehicleCheck",
        key: "trunk",
        count: 0,
      },
      {
        index: "/check/transportBillCheck",
        key: "transport",
        count: 0,
      },
    ],
  },
  mutations: {
    adduser(state, data) {
      state.user = data;
      window.sessionStorage.setItem(
        "user",
        encodeURIComponent(JSON.stringify(data))
      );
    },
    addnavigationMsg(state, data) {
      state.navigationMsg = data;
      window.sessionStorage.setItem("navigationMsg", JSON.stringify(data));
    },
    addTitleList(state, data) {
      let have = state.titleListNew.some((item) => {
        return item.path === data.path;
      });
      if (have) return "";
      if (state.titleListNew.length > 10) state.titleListNew.shift();
      state.titleListNew.push(data);
      window.sessionStorage.setItem(
        "titleListNew",
        JSON.stringify(state.titleListNew)
      );
    },
    delTitleList(state, index) {
      state.titleListNew.splice(index, 1);
      window.sessionStorage.setItem(
        "titleListNew",
        JSON.stringify(state.titleListNew)
      );
    },
    createConditions(state, obj) {
      obj.key &&
        (state.conditionsList[obj.key] = obj.val || { page: 1, limit: 15 });
    },
    clearConditions(state, key) {
      state.conditionsList[key] && (state.conditionsList[key] = null);
    },
    remove(state) {
      state.user = null;
      window.sessionStorage.removeItem("user");
      window.sessionStorage.removeItem("defaultactive");
    },
  },
  actions: {
  },
  modules: {},
  getters: {
    usergt(state) {
      return (
        state.user ||
        JSON.parse(decodeURIComponent(window.sessionStorage.getItem("user")))
      );
    },
    navigationMsgGt(state) {
      return (
        state.navigationMsg ||
        JSON.parse(window.sessionStorage.getItem("navigationMsg"))
      );
    },
  },
});
