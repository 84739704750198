import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/home.vue"),
    children: [
      {
        path: "/home/homepage",
        meta: {
          name: "首页",
        },
        component: () => import("@/views/home/template.vue"),
      },

      // 角色权限
      {
        path: "/rolemanagement/addusers",
        component: () => import("@/views/rolemanagement/addusers"),
      },
      {
        path: "/rolemanagement/menumanager",
        component: () => import("@/views/rolemanagement/menumanager"),
      },
      {
        path: "/rolemanagement/rolejob",
        component: () => import("@/views/rolemanagement/rolejob"),
      },
      {
        path: "/rolemanagement/rolepeople",
        component: () => import("@/views/rolemanagement/rolepeople"),
      },
      // 公告管理
      {
        path: "/notice/noticeList",
        name: "noticeList",
        component: () => import("@/views/notice/noticeList"),
      },
      {
        path: "/notice/addNotice",
        component: () => import("@/views/notice/addNotice"),
      },
      //业务统计
      {
        path: "/businessStatistic/jobDetails",
        component: () => import("@/views/businessStatistic/jobDetails"),
      },
      {
        path: "/businessStatistic/flow",
        component: () => import("@/views/businessStatistic/flow"),
      },
      //补能管理
      {
        path: "/charging/charts",
        component: () => import("@/views/charging/charts"),
      },
      {
        path: "/charging/chargingList",
        component: () => import("@/views/charging/chargingList"),
      },
      {
        path: "/charging/chargingMap",
        component: () => import("@/views/charging/chargingMap"),
      },
      // 运维管理
      {
        path: "/yunweiguanli/rizhi",
        name: "rizhi",
        component: () => import("@/views/yunweiguanli/rizhi"),
      },
      // 财务管理
      {
        path: "/baseInfo/owner",
        component: () => import("@/views/baseInfo/cargoOwner/index"),
      },
      {
        path: "/baseInfo/addOwner",
        component: () => import("@/views/baseInfo/cargoOwner/addOwner"),
      },
      {
        path: "/baseInfo/contacts",
        component: () => import("@/views/baseInfo/contacts/index"),
      },
      {
        path: "/baseInfo/cargoType",
        component: () => import("@/views/baseInfo/cargoType/index"),
      },
      {
        path: "/baseInfo/driverVehicle",
        component: () => import("@/views/baseInfo/driverVehicle/index"),
      },
      {
        path: "/baseInfo/addDriverVehicle",
        component: () => import("@/views/baseInfo/driverVehicle/addDriverVehicle"),
      },
      {
        path: "/baseInfo/fleet",
        component: () => import("@/views/baseInfo/fleet/index"),
      },
      {
        path: "/baseInfo/addFleet",
        component: () => import("@/views/baseInfo/fleet/addFleet"),
      },
      {
        path: "/baseInfo/chargingStation",
        component: () => import("@/views/baseInfo/charging/chargingStation/index"),
      },
      {
        path: "/baseInfo/stationPosition",
        component: () => import("@/views/baseInfo/charging/chargingStation/stationPosition"),
      },
      {
        path: "/baseInfo/chargingPile",
        component: () => import("@/views/baseInfo/charging/chargingPile/index"),
      },
      
      {
        path: "/baseInfo/chargingPort",
        component: () => import("@/views/baseInfo/charging/chargingPort/index"),
      },
      //货源管理
      {
        path: "/cargoSource/cargoSourceList",
        component: () => import("@/views/cargoSource/cargoSourceList/index"),
      },
      {
        path: "/cargoSource/addCargoSource",
        component: () =>
          import("@/views/cargoSource/cargoSourceList/addCargoSource"),
      },
      {
        path: "/cargoSource/cargoSourceDispatch",
        component: () =>
          import("@/views/cargoSource/cargoSourceDispatch/index"),
      },
      {
        path: "/cargoSource/cargoSourceDispatchEdit",
        component: () => import("@/views/cargoSource/cargoSourceDispatch/edit"),
      },
      {
        path: "/cargoSource/fleetCargoSourceDispatch",
        component: () =>
          import("@/views/cargoSource/fleetCargoSourceDispatch/index"),
      },
      {
        path: "/cargoSource/fleetCargoSourceDispatchEdit",
        component: () =>
          import("@/views/cargoSource/fleetCargoSourceDispatch/edit"),
      },
      {
        path: "/cargoSource/fleetCargoSourceDispatchDetails",
        component: () =>
          import("@/views/cargoSource/fleetCargoSourceDispatch/details"),
      },
      {
        path: "/cargoSource/cargoSourceHall",
        component: () => import("@/views/cargoSource/cargoSourceHall/index"),
      },
      {
        path: "/cargoSource/cargoSourceHallDetails",
        component: () => import("@/views/cargoSource/cargoSourceHall/details"),
      },
      //审核
      {
        path: "/check/cargoSourceCheck",
        component: () => import("@/views/check/cargoSourceCheck/index"),
      },
      {
        path: "/check/cargoSourceDetails",
        component: () => import("@/views/check/cargoSourceCheck/details"),
      },
      {
        path: "/check/fleetCheck",
        component: () => import("@/views/check/fleetCheck/index"),
      },
      {
        path: "/check/fleetDetails",
        component: () => import("@/views/check/fleetCheck/details"),
      },
      {
        path: "/check/cargoOwnerCheck",
        component: () => import("@/views/check/cargoOwnerCheck/index"),
      },
      {
        path: "/check/cargoOwnerDetails",
        component: () => import("@/views/check/cargoOwnerCheck/details"),
      },
      {
        path: "/check/driverVehicleCheck",
        component: () => import("@/views/check/driverVehicleCheck/index"),
      },
      {
        path: "/check/driverVehicleDetails",
        component: () => import("@/views/check/driverVehicleCheck/details"),
      },
      {
        path: "/check/transportBillCheck",
        component: () => import("@/views/check/transportBillCheck/index"),
      },
      {
        path: "/check/transportBillDetails",
        component: () => import("@/views/check/transportBillCheck/details"),
      },
      //运单
      {
        path: "/transportBill/transportBillList",
        component: () => import("@/views/transportBill/transportBillList/index"),
      },
      {
        path: "/transportBill/transportBillDetails",
        component: () => import("@/views/transportBill/transportBillList/details"),
      },
      {
        path: "/transportBill/poundBillList",
        component: () => import("@/views/transportBill/poundBillList/index"),
      },
      //资产管理
      {
        path: "/asset/leaseContract",
        component: () => import("@/views/asset/leaseContract/index"),
      },
      {
        path: "/asset/loanContract",
        component: () => import("@/views/asset/loanContract/index"),
      },
      {
        path: "/asset/vehicleAsset",
        component: () => import("@/views/asset/vehicleAsset/index"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    meta: {
      name: "登录",
    },
    component: () => import("@/views/login.vue"),
  },
  {
    path: "/singleLogin",
    name: "singleLogin",
    meta: {
      name: "登录",
    },
    component: () => import("@/views/singleLogin.vue"),
  },
  {
    path: "*",
    redirect: "/singleLogin",
  },
];
export default new VueRouter({
  routes,
});
