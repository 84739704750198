import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCountUp from "vue-countupjs"; //数字滚动插件
import "element-ui/lib/theme-chalk/base.css"; //折叠动画
// collapse 展开折叠
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
// 全局样式
import "@/assets/global.scss";
import "@/assets/components.scss";

//导入图标样式
import "@/assets/fonts/iconfont.css";

// element自带样式
import "@/assets/element-variables.scss";

// 按需导入element组件
import mElement from "@/plugins/mElement.js";
Vue.use(mElement);
Vue.component(CollapseTransition.name, CollapseTransition);
Vue.use(VueCountUp);

// 引入自定义的axios
import { http, instance, getBlob } from "@/plugins/axios.js";
Vue.prototype.$http = http;
Vue.prototype.$download = getBlob;

// 引入打印插件
import print from "@/plugins/print";
Vue.use(print);

let loding = null;
// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    if (config.url != "/api/stat/pendingNum") {
      loding = Vue.prototype.$loading.service({
        lock: true,
        text: "数据请求中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    }
    if (
      config.url === "/captcha.jpg" ||
      config.url === "/sys/login" ||
      config.url === "/transport/sso/check"
    )
      return config;
    else {
      config.headers["token"] = JSON.parse(
        decodeURIComponent(window.sessionStorage.getItem("user"))
      ).token;
      return config;
    }
  },
  function (error) {
    // 对请求错误做些什么
    if (loding) {
      loding.close();
    }
    return Promise.reject(error);
  }
);

// 添加响应拦截
instance.interceptors.response.use(
  (response) => {
    if (loding) {
      loding.close();
    }
    if (!response.data) throw new Error("响应异常");
    switch (response.data.code) {
      case 401:
        returnlogin();
        break;
      default:
        return response;
    }
  },
  function (error) {
    // 对响应错误做点什么
    if (loding) {
      loding.close();
    }
    return Promise.reject(error);
  }
);
let num = 0;
function returnlogin() {
  if (num) return "";
  Vue.prototype.$messageBox
    .confirm("登录过期", "提示", {
      confirmButtonText: "确定",
      showClose: false,
      showCancelButton: false,
      type: "error",
    })
    .then((_) => {
      router.replace("/");
    });
  num++;
}

// 添加路由导航全局守卫
router.beforeEach((to, from, next) => {
  document.title = "济源新能智运";
  if (
    to.path === "/login" ||
    to.path === "/singleLogin" ||
    to.name === "pageinfoonly" ||
    to.name === "paypageonly"
  ) {
    next();
  } else {
    var obj = JSON.parse(
      decodeURIComponent(window.sessionStorage.getItem("user"))
    );
    if (!obj || !obj.token) return next("/");
    next();
  }
});

Vue.filter("formatMsgFun", (val) => {
  if (val) return val;
  return "";
});

Vue.mixin({
  methods: {
    mydateFormat(obj, val) {
      if (!obj) return "";
      let dt = new Date(obj),
        yy = dt.getFullYear(),
        mon = (dt.getMonth() + 1 + "").padStart(2, 0),
        dd = (dt.getDate() + "").padStart(2, 0),
        hh = (dt.getHours() + "").padStart(2, 0),
        min = (dt.getMinutes() + "").padStart(2, 0),
        ss = (dt.getSeconds() + "").padStart(2, 0);
      if (val == "年月日") return `${yy}-${mon}-${dd}`;
      if (val == "时分秒") return `${yy}-${mon}-${dd} ${hh}:${min}:${ss}`;
    },
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
